import * as React from 'react';
import type { HeadFC } from 'gatsby';
import LayoutCenter from '../components/LayoutCenter';
import ComingSoonCounter from '../components/ComingSoonCounter';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';
const ComingSoonPage = () => {
  const { t } = useTranslation();

  return (
    <LayoutCenter variant="dark">
      <div className="container my-auto max-w-3xl">
        <div className="flex flex-col gap-4 text-center">
          <div className="mb-6">
            <h1 className=" mb-2 text-5xl font-bold tracking-tight sm:text-6xl">
              {t('coming_soon.title')}
            </h1>
            <p className="text-lg sm:text-xl">{t('coming_soon.text')}</p>
          </div>
          <ComingSoonCounter date={new Date('2022-10-5')} />
        </div>
      </div>
    </LayoutCenter>
  );
};

export default ComingSoonPage;

export const Head: HeadFC = ({ location, pageContext }) => {
  return <Seo location={location} />;
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
