import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  date: Date;
};

type PropsItem = {
  title: number | null;
  text: string;
};

type TimeLeft = {
  days: number | null;
  hours: number | null;
  minutes: number | null;
  seconds: number | null;
};

const Item = ({ title, text }: PropsItem) => {
  return (
    <div className="flex flex-col rounded bg-emerald-500 py-6 px-2 text-center text-blue sm:px-6">
      <div className="min-h-[1.2em] text-2xl font-bold sm:text-4xl">{title}</div>
      <div className=" text-xs sm:text-base ">{text}</div>
    </div>
  );
};

const ComingSoonCounter = ({ date }: Props) => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = React.useState({
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  } as TimeLeft);
  const [intervalId, setIntervalId] = React.useState(0 as NodeJS.Timer | number);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const diff = date.getTime() - now.getTime();
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);
    setIntervalId(interval);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div className="grid grid-cols-4 justify-center gap-2 text-center">
        <Item title={timeLeft.days} text={t('days')} />
        <Item title={timeLeft.hours} text={t('hours')} />
        <Item title={timeLeft.minutes} text={t('minutes')} />
        <Item title={timeLeft.seconds} text={t('seconds')} />
      </div>
    </div>
  );
};

export default ComingSoonCounter;
