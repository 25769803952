import React from 'react';
import Header from './Header';

type Props = {
  children: React.ReactNode;
  variant?: 'dark' | 'light';
};

const LayoutCenter = ({ children, variant }: Props) => {
  React.useEffect(() => {
    const setScreenHeight = () => {
      const screenH = window.innerHeight;
      document.documentElement.style.setProperty('--vh', `${screenH}px`);
    };

    setScreenHeight();

    window.addEventListener('resize', setScreenHeight);

    return () => {
      window.removeEventListener('resize', setScreenHeight);
    };
  }, []);

  return (
    <div
      className={`flex h-screen flex-col 
      ${variant === 'dark' ? 'bg-blue text-white' : 'bg-white text-gray-900'}`}
    >
      <Header showNavigation={false} />
      <div className="flex grow flex-col">{children}</div>
    </div>
  );
};

export default LayoutCenter;
